export const responsiveConfig = [
  {
    breakpoint: 780,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,

      // dots: true,
      // centerMode: true,
      // className: "center",
      // centerPadding: "100px",
    },
  },
  {
    breakpoint: 550,
    settings: {
      // centerPadding: "30px",
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,

      // dots: true,
      // centerMode: true,
      // className: "center",
    },
  },
  {
    breakpoint: 330,
    settings: {
      // centerPadding: "40px",
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,

      // dots: true,
      // centerMode: true,
      // className: "center",
    },
  },
  {
    breakpoint: 420,
    settings: {
      // centerPadding: "50px",
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      // dots: true,
      // centerMode: true,
      // className: "center",
    },
  },
];
