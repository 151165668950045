import React from "react";
import "./AboutUs.scss";

export default function AXboutUs() {
  return (
    <div className="rp_about-us">
      <main className="main-content">
        <h1>About Roop Jewellers's</h1>
        <section className="brand-story">
          <h2>Brand Story</h2>

          <div className="story-section">
            <h3>Initial Foray</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatem pariatur, alias, rem natus culpa aspernatur tempora
              explicabo, in eveniet esse consequuntur voluptate maiores?
              Dignissimos, fugit voluptates, repellat iste non tempora quisquam
              blanditiis, harum asperiores quidem autem odio veritatis voluptas.
              Totam, aspernatur, nihil et ullam dolore dicta suscipit
              repudiandae ipsa dolorum deserunt odit veritatis sit consectetur
              nesciunt autem nostrum! Nostrum, commodi dolore sequi unde
              corporis illo ab velit harum, aspernatur officiis nisi a, nobis
              accusamus quae? Repudiandae mollitia cumque nobis voluptates
              numquam doloribus ab fugit amet voluptas corporis quos, reiciendis
              id.
            </p>
          </div>

          <div className="story-section">
            <h3>Timeless Appeal</h3>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatem pariatur, alias, rem natus culpa aspernatur tempora
              explicabo, in eveniet esse consequuntur voluptate maiores?
              Dignissimos, fugit voluptates, repellat iste non tempora quisquam
              blanditiis, harum asperiores quidem autem odio veritatis voluptas.
              Totam, aspernatur, nihil et ullam dolore dicta suscipit
              repudiandae ipsa dolorum deserunt odit veritatis sit consectetur
              nesciunt autem nostrum! Nostrum, commodi dolore sequi unde
              corporis .
            </p>
          </div>

          <div className="story-section">
            <h3>Epitomizing Excellence</h3>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatem pariatur, alias, rem natus culpa aspernatur tempora
              explicabo, in eveniet esse consequuntur voluptate maiores?
              Dignissimos, fugit voluptates, repellat iste non tempora quisquam
              blanditiis, harum asperiores quidem autem odio veritatis voluptas.
              Totam, aspernatur, nihil et ullam dolore dicta suscipit
              repudiandae ipsa dolorum deserunt odit veritatis sit consectetur
              nesciunt autem nostrum! Nostrum, commodi dolore sequi unde
              corporis . Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatem pariatur, alias, rem natus culpa aspernatur tempora
              explicabo, in eveniet esse consequuntur voluptate maiores?
              Dignissimos,.
            </p>
          </div>

          <div className="story-section">
            <h3>Success Secrets</h3>
            <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Voluptatem pariatur, alias, rem natus culpa aspernatur tempora
              explicabo, in eveniet esse consequuntur voluptate maiores?
              Dignissimos, fugit voluptates, repellat iste non tempora quisquam
              blanditiis, harum asperiores quidem autem odio veritatis voluptas.
              Totam, aspernatur, nihil et ullam dolore dicta suscipit
              repudiandae ipsa dolorum deserunt odit veritatis sit consectetur
              nesciunt autem nostrum! Nostrum, commodi dolore sequi unde
              corporis .
            </p>
          </div>
        </section>
      </main>
    </div>
  );
}
